import classNames from 'classnames'
import React, { Fragment } from 'react'

import ContentfulRichText from '../../_elements/ContentfulRichText'
import RichTextLayout from '../RichTextLayout'
import SideColumnLayout from '../SideColumnLayout'

import styles from './ingress-layout.module.scss'

interface IngressLayoutProps {
  listLabel?: string | null
  listChildren: React.ReactChild[] | null | undefined | false
  text: string | { html: string } | { json: any } | null | undefined

  isNavigationList?: boolean
  isSmallerText?: boolean

  className?: string
}

export default function IngressLayout({
  listLabel,
  listChildren,
  text,

  isNavigationList = false,
  isSmallerText = false,

  className,
}: IngressLayoutProps) {
  const ListContainerElement = isNavigationList ? 'nav' : Fragment

  return (
    <SideColumnLayout
      className={classNames(styles.container, className)}
      sideColumn={
        listChildren && (
          <ListContainerElement>
            {listLabel && <div className={styles.listLabel}>{listLabel}</div>}

            <ul className={styles.list}>
              {React.Children.map(listChildren, (item) => (
                <li key={item} className={styles.item}>
                  {item}
                </li>
              ))}
            </ul>
          </ListContainerElement>
        )
      }
      mainColumn={
        text && (
          <RichTextLayout
            className={classNames(
              styles.text,
              isSmallerText && styles.textIsSmaller
            )}
          >
            {typeof text === 'string' ? (
              <p>{text}</p>
            ) : (
              <>
                {'html' in text ? (
                  <div dangerouslySetInnerHTML={{ __html: text.html }} />
                ) : (
                  <ContentfulRichText json={text.json} />
                )}
              </>
            )}
          </RichTextLayout>
        )
      }
    />
  )
}
