import classNames from 'classnames'
import Img from 'gatsby-image'
import React, { ReactNode } from 'react'

import { ContentfulAsset } from '../../../@import-types/contentful/Asset.types'
import { CTATargetId, PageColorDataProps } from '../../../constants/GlobalDOM'
import useMicroCopy from '../../../hooks/globalContent/useMicroCopy'
import useIsDesktop from '../../../hooks/useIsDesktop'
import { getComplementaryColor } from '../../../utils/colorUtils'
import { formatLocaleDate } from '../../../utils/dateUtils'
import { animateScrollToCenter } from '../../../utils/scrollUtils'
import BaseDate from '../../_elements/BaseDate'
import CTAButton from '../../_elements/_buttons/CTAButton'
import LayoutContainer from '../../_layouts/LayoutContainer'
import HeroHeading from '../../_typography/HeroHeading'

import styles from './image-hero.module.scss'

interface ImageHeroProps {
  title: string | null | undefined
  titleLabel?: string | null | undefined
  subtitle?: ReactNode

  publishDate?: string | Date | null

  backgroundColor?: string | null

  image: ContentfulAsset | null | undefined

  smallerMobileHeading?: boolean | null
  hasSignUpForm: boolean
  hasHubspotForm: boolean
  hubspotFormId?: string
  className?: string
}

export default function ImageHero({
  title,
  titleLabel,
  subtitle,

  publishDate,

  backgroundColor,

  image,

  smallerMobileHeading,
  hasSignUpForm,
  hasHubspotForm,
  hubspotFormId,

  className,
}: ImageHeroProps) {
  const isDesktop = useIsDesktop()

  const textColor = getComplementaryColor(backgroundColor)
  const buttonText = useMicroCopy('CTA.signUp')

  function onCTAClick() {
    let ctaTargetElement
    if (hasHubspotForm) {
      ctaTargetElement = document.querySelector<HTMLElement>(
        `#${hubspotFormId}`
      )
    } else {
      ctaTargetElement = document.querySelector<HTMLElement>(
        `#${ctaTargetElement}`
      )
    }

    if (ctaTargetElement) {
      animateScrollToCenter(ctaTargetElement)
      return
    }
  }

  return (
    <section className={classNames(styles.container, className)}>
      <LayoutContainer
        className={styles.innerContainer}
        style={{ color: textColor }}
      >
        <div className={styles.textsContainer}>
          {titleLabel && <p className={styles.titleLabel}>{titleLabel}</p>}
          <HeroHeading
            className={styles.title}
            dangerouslySetInnerHTML={{ __html: title ?? '' }}
          />

          {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
        </div>
        {hasSignUpForm ||
          (hasHubspotForm && (
            <div className={styles.ctaContainer}>
              <CTAButton
                className={styles.ctaButton}
                isSmaller={!isDesktop}
                onClick={onCTAClick}
              >
                {buttonText}
              </CTAButton>
            </div>
          ))}

        <div className={styles.imageContainer}>
          <div
            className={styles.backgroundColor}
            style={{ backgroundColor: backgroundColor! }}
            {...PageColorDataProps}
          />

          <BaseDate
            className={styles.publishDate}
            date={publishDate}
            formatDate={(locale, date) =>
              formatLocaleDate(locale, date, {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              })
            }
          />

          {image && image?.fluid &&  (
            <Img
              className={styles.image}
              fluid={image.fluid}
              alt={image.description || image.title || ""}
            />
          )}
        </div>
      </LayoutContainer>
    </section>
  )
}
