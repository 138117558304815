import { useMemo } from 'react'

import createAnchorId from '../../utils/createAnchorId'
import { AllContentAreaTypes } from '../ContentArea/ContentArea'
import { TeaserCarouselBlockTypename } from '../_blocks/TeaserCarouselBlock/TeaserCarouselBlock'
import { TextBlockTypename } from '../_blocks/TextBlock/TextBlock'

const anchorTypenames = [TextBlockTypename, TeaserCarouselBlockTypename]

export function useContentAreaAnchorLinks(
  contentArea: AllContentAreaTypes[] | null | undefined
) {
  return useMemo(() => {
    if (!contentArea) {
      return null
    }

    return contentArea
      .filter((item) => {
        if (!anchorTypenames.includes(item.__typename!)) {
          return false
        }

        if ('title' in item && typeof item.title !== 'string') {
          return false
        }

        if (item.__typename === TextBlockTypename && item.renderTitleAsQuote) {
          return false
        }

        return true
      })
      .map((item) => ({
        title: item.title,
        anchorId: createAnchorId(item.title),
      }))
  }, [contentArea])
}
