import { graphql } from 'gatsby'
import React, { useEffect, useState } from 'react'

import { BlogPostContent } from '../../@import-types/contentful/BlogPost.types'
import ContentArea from '../../components/ContentArea'
import Ingress from '../../components/Ingress'
import Layout from '../../components/Layout'
import TeaserCarouselBlock from '../../components/_blocks/TeaserCarouselBlock'
import ImageHero from '../../components/_heroes/ImageHero'
import EmployeeGrid from '../../components/_layouts/EmployeeGrid'
import { RootPageQuery } from '../../graphql/global.graphql'
import useMicroCopy from '../../hooks/globalContent/useMicroCopy'
import { getComplementaryColor } from '../../utils/colorUtils'

type BlogPostProps = RootPageQuery<{
  contentfulBlogPost: BlogPostContent
  related: { edges: Array<{ node: BlogPostContent }> }
  other: { edges: Array<{ node: BlogPostContent }> }
}>

export default function BlogPost({ data, pageContext }: BlogPostProps) {
  const { contentfulBlogPost, commonContent, related, other } = data

  const [hasSignUpForm, setHasSignUpForm] = useState<boolean>(false)

  const [hasHubspotForm, setHasHubspotForm] = useState<boolean>(false)
  const [hubspotFormId, setHubspotFormId] = useState<string | undefined>(undefined)

  const {
    authors,
    content,
    heroBackgroundColor,
    heroImage,
    introduction,
    id,
    publishDate,
    shortDescription,
    title,
    titleLabel,
  } = contentfulBlogPost

  const textColor = getComplementaryColor(heroBackgroundColor)
  const authorSingularLabel = useMicroCopy('text.author', commonContent)
  const authorPluralLabel = useMicroCopy('text.authors', commonContent)
  const realtedBlogPostsTitle = useMicroCopy(
    'text.related_blog_posts_title',
    commonContent
  )

  const authorLabel =
    authors?.length! > 1 ? authorPluralLabel : authorSingularLabel

  useEffect(() => {
    if (contentfulBlogPost.content) {
      const hasSignUpForm = contentfulBlogPost.content.find(
        (content) => content.__typename === 'ContentfulBlockSignUpForm'
      )

      if (hasSignUpForm) {
        setHasSignUpForm(true)
      }
    }
    if (contentfulBlogPost.content) {      
      const hubspotForm = contentfulBlogPost.content.find(
        (content) => {          
          return content.__typename === "ContentfulBlockHubspotForm"}
      )

      if (hubspotForm) {
        setHubspotFormId(hubspotForm.target)
        setHasHubspotForm(true)
      }
    }
  }, [contentfulBlogPost])
  

  // Blog posts from the same category
  // Filters out blog posts with the same id as the current blog post
  const relatedBlogPosts = related.edges
    .map((blogPost: any) => ({
      ...blogPost.node,
    }))
    .filter((blogPost: any) => blogPost.id !== id)

  const showRelatedBlogPosts = relatedBlogPosts.length > 0

  // Other blog posts are shown when there are no other blog posts in the same category
  const otherBlogPosts = other.edges
    .map((blogPost: any) => ({
      ...blogPost.node,
    }))
    .filter((blogPost: any) => blogPost.id !== id)

  const showOtherBlogPosts = relatedBlogPosts.length === 0

  return (
    <Layout
      data={data}
      basePageContent={contentfulBlogPost}
      pageContext={pageContext}
      pageBackgroundColor={heroBackgroundColor}
      pageTextColor={textColor}
    >
      <ImageHero
        title={title}
        titleLabel={titleLabel}
        subtitle={shortDescription}
        publishDate={publishDate}
        backgroundColor={heroBackgroundColor}
        image={heroImage ?? null}
        hasSignUpForm={hasSignUpForm} //TO DO: delete everything with signupform if hubspot form takes over
        hasHubspotForm={hasHubspotForm}
        hubspotFormId={hubspotFormId}
      />

      {introduction && (
        <Ingress
          text={introduction}
          contentArea={content}
          projectName={title}
        />
      )}

      {content && <ContentArea content={content} />}

      {authors && (
        <EmployeeGrid employees={authors} title={`${authorLabel}.`} />
      )}

      {showRelatedBlogPosts && (
        <TeaserCarouselBlock
          title={realtedBlogPostsTitle}
          teasers={relatedBlogPosts}
        />
      )}

      {showOtherBlogPosts && (
        <TeaserCarouselBlock
          title={realtedBlogPostsTitle}
          teasers={otherBlogPosts}
        />
      )}
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostPageById(
    $id: String!
    $startPageId: String!
    $locale: String!
    $tags: [String]
  ) {
    contentfulBlogPost(id: { eq: $id }) {
      ...BasePageFragment

      title

      titleLabel

      publishDate

      shortDescription

      heroBackgroundColor

      authors {
        ...EmployeeTeaserFragment
      }

      introduction {
        json
      }

      heroImage {
        title
        description
        fluid(quality: 90, maxWidth: 1400) {
          ...GatsbyContentfulFluid_withWebp
        }
      }

      content {
        __typename

        ... on Node {
          ...ContentAreaFragment
        }
      }

      tags {
        text
        id
      }
    }

    related: allContentfulBlogPost(
      filter: {
        tags: { elemMatch: { id: { in: $tags } } }
        node_locale: { eq: $locale }
      }
      sort: { order: DESC, fields: createdAt }
      limit: 10
    ) {
      edges {
        node {
          ...BlogPostTeaserFragment
        }
      }
    }

    other: allContentfulBlogPost(
      filter: { node_locale: { eq: $locale } }
      sort: { order: DESC, fields: createdAt }
      limit: 3
    ) {
      edges {
        node {
          ...BlogPostTeaserFragment
        }
      }
    }

    ...GlobalFragment
  }
`
