import classNames from 'classnames'
import React from 'react'

import useMicroCopy from '../../hooks/globalContent/useMicroCopy'
import { animateScrollToWithMargin } from '../../utils/scrollUtils'
import { AllContentAreaTypes } from '../ContentArea/ContentArea'
import { getSegmentLinkTrackingdData } from '../_functional/Segment/helpers'
import IngressLayout from '../_layouts/IngressLayout'
import LayoutContainer from '../_layouts/LayoutContainer'

import { useContentAreaAnchorLinks } from './Ingress.hooks'

import styles from './ingress.module.scss'

interface IngressProps {
  text: string | { html: string } | { json: any }
  contentArea: AllContentAreaTypes[] | null | undefined
  projectName: string | null | undefined

  className?: string
}

export default function Ingress({
  text,
  contentArea,
  projectName,

  className,
}: IngressProps) {
  const quickLinksLabel = useMicroCopy('text.quick_links')

  const anchorLinks = useContentAreaAnchorLinks(contentArea)

  function onAnchorLinkClick(
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) {
    const [, targetElementId] = event.currentTarget.href.split('#')

    if (!targetElementId) {
      return
    }

    const targetElement = document.getElementById(targetElementId)
    if (!targetElement) {
      return
    }

    event.preventDefault()
    animateScrollToWithMargin(targetElement)
  }

  return (
    <LayoutContainer
      className={classNames(styles.container, className)}
      withMargin
      smallMarginBottom
    >
      <IngressLayout
        listLabel={quickLinksLabel}
        listChildren={
          anchorLinks &&
          anchorLinks.length > 0 &&
          anchorLinks.map((item) => (
            <a
              key={item.anchorId}
              className={styles.link}
              href={`#${item.anchorId}`}
              onClick={() => {
                onAnchorLinkClick
                getSegmentLinkTrackingdData({
                  anchor_text: projectName + ' - ' + quickLinksLabel,
                  position: 'body',
                  url: `#${item.anchorId}`,
                  destination: 'internal',
                })
              }}
            >
              {item.title}
            </a>
          ))
        }
        text={text}
        isNavigationList
      />
    </LayoutContainer>
  )
}
